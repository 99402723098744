import { useQuery, UseQueryResult } from '@tanstack/react-query'
import HttpClient from 'services/httpClient'
import { GetRecordsResponse } from 'services/occurrence/types'
import { endpoints } from 'services/occurrence/endpoints'
import buildUrl from 'utilities/buildUrl'

export function useGetRecords(
  occurrenceId?: string,
  enabled = true,
): UseQueryResult<GetRecordsResponse> {
  return useQuery({
    queryKey: ['cftv-records', occurrenceId],
    queryFn: async () => {
      const response = await HttpClient.get<GetRecordsResponse[]>(
        buildUrl({
          route: endpoints.getRecords,
          params: { occurrenceId },
        }),
      )
      return response.data
    },
    enabled: !!occurrenceId && enabled,
  })
}
